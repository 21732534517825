import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js'; // Asegúrate de especificar la extensión del archivo aquí
import reportWebVitals from './reportWebVitals.js';
import axios from 'axios';
import * as Sentry from '@sentry/react';

// Configuración de Sentry (comenta la integración de BrowserTracing)
Sentry.init({
  dsn: 'https://461bab567fdacbbcac6fecf5fa62bc75@o4507510614917120.ingest.us.sentry.io/4507510619308032',
  // Deshabilita temporalmente la integración de BrowserTracing
  integrations: [
    // new Integrations.BrowserTracing(), // Comentar esta línea temporalmente
  ],
  tracesSampleRate: 1.0, // Mantén las demás configuraciones
});

// Configuración de Axios
axios.defaults.baseURL = 'process.env.REACT_APP_API_URL';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si quieres empezar a medir el rendimiento de tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();

ReactDOM.render(<App />, document.getElementById('root'));
