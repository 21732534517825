import React from 'react';
import './App.css';
import PatientForm from './components/PatientForm.js'; // Asegúrate de especificar la extensión del archivo aquí
import PatientList from './components/PatientList.js'; // Asegúrate de especificar la extensión del archivo aquí
import OtroComponente from './components/OtroComponente.js'; // Asegúrate de especificar la extensión del archivo aquí
import MedicalRecords from './components/MedicalRecords.js'; // Asegúrate de especificar la extensión del archivo aquí

const App = () => {
  return (
    <div className="App">
      <h1>Medical Software</h1>
      <PatientForm />
      <PatientList />
      <OtroComponente />
      <MedicalRecords /> {/* Asegúrate de especificar la extensión del archivo aquí */}
    </div>
  );
};

export default App;
